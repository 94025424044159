import React, { useState } from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import {t} from "i18next";

function About() {
    const [showExperience, setShowExperience] = useState(false);
    const [showSkills, setShowSkills] = useState(false);
    const { t } = useTranslation();

    const toggleExperience = () => setShowExperience(!showExperience);
    const toggleSkills = () => setShowSkills(!showSkills);

    return (
        <div style={{marginTop: "100px"}}>
            <p className="luxurious-roman">{t('About.intro')} <span className="highlight">Nabil.</span></p>
            <p className="shippori-mincho">
                {t('About.passionate')}<br/> {t('About.curiosity')}
            </p>
            <br />

            <div className="row">
                <div className="col-1"></div>
                <div className="col-12 col-md-4 d-flex justify-content-center">
                    <img
                        src="/images/Mannette_Jeux_sliced.png"
                        alt="MannetteDeJeu"
                        className="img-fluid rounded-5 sizing-custom hovered mb-3"
                    />
                </div>
                <div className="col-1"></div>
                <div className="col-12 col-md-6 d-flex justify-content-center">
                    <img
                        src="/images/muscu.png"
                        alt="GigachadProprietiesMissing"
                        className="img-fluid rounded-5 sizing-custom hovered"
                        style={{maxWidth: '80%'}}
                    />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-1"></div>
                <div className="col-11">
                    <Experience toggleExperience={toggleExperience} showExperience={showExperience}/>
                    <Skills toggleSkills={toggleSkills} showSkills={showSkills}/>
                </div>
            </div>
        </div>
    );
}

export default About;

const Experience = ({toggleExperience, showExperience}) => {
    return (
        <div>
            <p
                onClick={toggleExperience}
                style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            >
                <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                        color: '#2E67F8',
                        marginRight: '8px',
                        transition: 'transform 0.3s',
                        transform: showExperience ? 'rotate(90deg)' : 'none',
                    }}
                />
                {t('About.experienceProfessionelle')}
            </p>
            <div
                className={`collapse-container ${showExperience ? 'open' : ''}`}
                style={{transition: 'max-height 0.5s ease-out'}}
            >
                <ul>
                    <li><strong>{t('About.studentWork')}</strong></li>
                    <ul>
                        <li>{t('About.studentWorkDetails.locatif')}</li>
                        <li>{t('About.studentWorkDetails.antoine')}</li>
                    </ul>
                </ul>
            </div>
        </div>
    );
}

const Skills = ({toggleSkills, showSkills}) => {
    const [openSections, setOpenSections] = useState({
        languages: false,
        frameworks: false,
        tools: false,
        design: false,
        methodologies: false,
        testing: false,
    });

    const toggleSection = (section) => {
        setOpenSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };
    return (
        <div>
            <p
                onClick={toggleSkills}
                style={{cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            >
                <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                        color: '#2E67F8',
                        marginRight: '8px',
                        transition: 'transform 0.3s',
                        transform: showSkills ? 'rotate(90deg)' : 'none',
                    }}
                />
                {t('About.competences')}
            </p>
            <div
                className={`collapse-container ${showSkills ? 'open' : ''}`}
                style={{transition: 'max-height 0.5s ease-out'}}
            >
                <ul>
                    {/* Langages de programmation */}
                    <li>
                        <strong onClick={() => toggleSection('languages')} style={{cursor: 'pointer'}}>
                            <i className="fas fa-code"></i> {t('About.languagesProgramming')}
                            <i
                                className={`fas ${openSections.languages ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                style={{marginLeft: '10px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection('languages');
                                }} // Empêche la propagation de l'événement
                            />
                        </strong>
                        {openSections.languages && (
                            <ul>
                                <li><i className="fab fa-java"></i> Java (orienté objet)</li>
                                <li><i className="fab fa-csharp"></i> C#</li>
                                <li><i className="fab fa-js-square"></i> TypeScript</li>
                                <li><i className="fab fa-python"></i> Python</li>
                                <li><i className="fab fa-dart"></i> Dart</li>
                                <li><i className="fas fa-database"></i> SQL</li>
                            </ul>
                        ) }
                    </li>

                    {/* Frameworks et bibliothèques */}
                    <li>
                        <strong onClick={() => toggleSection('frameworks')} style={{cursor: 'pointer'}}>
                            <i className="fas fa-cogs"></i> {t('About.frameworksBibliotheques')}
                            <i
                                className={`fas ${openSections.frameworks ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                style={{marginLeft: '10px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection('frameworks');
                                }}
                            />
                        </strong>
                        {openSections.frameworks && (
                            <ul>
                                <li><i className="fab fa-angular"></i> Angular</li>
                                <li><i className="fab fa-react"></i> React.js</li>
                                <li><i className="fab fa-flutter"></i> Flutter</li>
                                <li><i className="fab fa-python"></i> Flask</li>
                                <li><i className="fas fa-cogs"></i> Spring</li>
                                <li><i className="fab fa-java"></i> Hibernate</li>
                                <li><i className="fas fa-cogs"></i> Maven</li>
                            </ul>
                        )}
                    </li>

                    {/* Outils et plateformes */}
                    <li>
                        <strong onClick={() => toggleSection('tools')} style={{cursor: 'pointer'}}>
                            <i className="fas fa-tools"></i> {t('About.outilsPlateformes')}
                            <i
                                className={`fas ${openSections.tools ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                style={{marginLeft: '10px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection('tools');
                                }} // Empêche la propagation de l'événement
                            />
                        </strong>
                        {openSections.tools && (
                            <ul>
                                <li><i className="fab fa-intellij"></i> IntelliJ IDEA</li>
                                <li><i className="fab fa-microsoft"></i> Visual Studio Code (VS Code)</li>
                                <li><i className="fab fa-git-square"></i> Git / GitHub</li>
                                <li><i className="fas fa-plug"></i> Postman (API Platform)</li>
                                <li><i className="fab fa-firebase"></i> Firebase</li>
                                <li><i className="fab fa-figma"></i> Figma</li>
                            </ul>
                        )}
                    </li>

                    {/* Conception et architecture */}
                    <li>
                        <strong onClick={() => toggleSection('design')} style={{cursor: 'pointer'}}>
                            <i className="fas fa-sitemap"></i> {t('About.conceptionArchitecture')}
                            <i
                                className={`fas ${openSections.design ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                style={{marginLeft: '10px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection('design');
                                }} // Empêche la propagation de l'événement
                            />
                        </strong>
                        {openSections.design && (
                            <ul>
                                <li><i className="fas fa-project-diagram"></i> UML / Architecture MVC / Modélisation
                                    logicielle
                                </li>
                                <li><i className="fas fa-th-large"></i> Design Patterns</li>
                                <li><i className="fas fa-project-diagram"></i> Data structures & Algorithms</li>
                            </ul>
                        )}
                    </li>

                    {/* Méthodologies et gestion de projet */}
                    <li>
                        <strong onClick={() => toggleSection('methodologies')} style={{cursor: 'pointer'}}>
                            <i className="fas fa-project-diagram"></i> {t('About.gestionProjet')}
                            <i
                                className={`fas ${openSections.methodologies ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                style={{marginLeft: '10px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection('methodologies');
                                }} // Empêche la propagation de l'événement
                            />
                        </strong>
                        {openSections.methodologies && (
                            <ul>
                                <li><i className="fas fa-sync-alt"></i> Scrum / Agile</li>
                                <li><i className="fab fa-jira"></i> Jira</li>
                            </ul>
                        )}
                    </li>

                    {/* Tests et développement */}
                    <li>
                        <strong onClick={() => toggleSection('testing')} style={{cursor: 'pointer'}}>
                            <i className="fas fa-laptop-code"></i> {t('About.testDev')}
                            <i
                                className={`fas ${openSections.testing ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                                style={{marginLeft: '10px', cursor: 'pointer'}}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSection('testing');
                                }} // Empêche la propagation de l'événement
                            />
                        </strong>
                        {openSections.testing && (
                            <ul>
                                <li><i className="fas fa-check-square"></i> Test-driven development (TDD)</li>
                                <li><i className="fab fa-java"></i> JUnit</li>
                                <li><i className="fab fa-java"></i> Mockito</li>
                                <li><i className="fab fa-java"></i> MockMvc</li>
                            </ul>
                        )}
                    </li>
                </ul>
            </div>
        </div>
    );
}