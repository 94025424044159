import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, useLocation  } from 'react-router-dom';
import Work from './components/work/Work';
import About from './components/about/About';
import NavbarMenu from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import React from "react";
import LandingPage from "./components/landingPage/LandingPage";
import PortfolioPage from "./components/work/portfolioPage/PortfolioPage";
import Contact from "./components/contact/Contact";
import EndLandingPage from "./components/endLandingPage/EndLandingPage";
import WelcomePage from "./components/welcomePage/WelcomePage";
import { I18nextProvider } from 'react-i18next';
import i18n from './internationalization/i18n';

function App() {
  return (
      <div className="App">
        <Router>
            <I18nextProvider i18n={i18n}>
                <AppWithNavbar />
            </I18nextProvider>
        </Router>
      </div>
  );
}

function AppWithNavbar() {
  const location = useLocation();
  const isWelcomePage = location.pathname === "/welcome";

  return (
      <div className="container-fluid">
        {!isWelcomePage && <NavbarMenu />}
        <Routes>
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/landingPage" element={<LandingPage />} />
          <Route path="/work" element={<Work />} />
          <Route path="/portfolio/:year" element={<PortfolioPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/endLandingPage" element={<EndLandingPage />} />
          <Route path="/" element={<WelcomePage />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
        <Footer />
      </div>
  );
}

export default App;
