import React from "react";
import './LandingPage.css';
import Work from "../work/Work";
import About from "../about/About";
import EndLandingPage from "../endLandingPage/EndLandingPage";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function LandingPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigation = () => {
        navigate('/contact');
    };

    return (
        <div>
            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row w-100 justify-content-center">
                    <div className="col-lg-5 d-flex justify-content-center align-items-center order-2 order-lg-1">
                        <img src="/images/Nabil.png" alt="ImageOfMe" className="rounded-circle img-fluid"/>
                    </div>

                    <div className="col-lg-5 d-flex flex-column justify-content-center order-1 order-lg-2">
                        <h1>{t('LandingPage.intro')}</h1>
                        <h2 className="letter-spacing">{t('LandingPage.imA')} <span className="highlight">{t('LandingPage.carriere')}</span></h2>
                        <p>{t('LandingPage.objectif')}</p>
                        <div className="d-flex justify-content-center">
                            <a href="https://www.linkedin.com/in/hnabil07/" target="_blank" rel="noopener noreferrer">
                                <button className="linkedin-btn">LinkedIn</button>
                            </a>
                            <button className="hire-btn ms-2" onClick={handleNavigation}>{t('LandingPage.talk')}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Work/>
            <About/>
            <EndLandingPage/>
        </div>
    );
}

export default LandingPage;
