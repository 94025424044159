import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);
    };

    return (
        <div className="language-switcher mx-3">
            <span
                className="language-option"
                onClick={() => handleLanguageChange('fr')}
            >
                FR
            </span>
            <span className="separator">|</span>
            <span
                className="language-option"
                onClick={() => handleLanguageChange('en')}
            >
                EN
            </span>
        </div>
    );
};

export default LanguageSwitcher;
