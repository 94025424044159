import React, { useState, useLayoutEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import '../../App.css';
import './Navbar.css';
import LanguageSwitcher from "../../internationalization/languageSwitcher/LanguageSwitcher";
import { useTranslation } from 'react-i18next';

function NavbarMenu() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useLayoutEffect(() => {
    setIsNavbarOpen(false);
  }, [location]);

  const navbarClass = isScrolled || isNavbarOpen ? 'scrolled-navbar' : '';

  return (
      <Navbar expand="lg" className={navbarClass}>
        <Container fluid className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="text-white me-2">©</span>
            Nabil Hasnaoui
          </div>
          <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setIsNavbarOpen(!isNavbarOpen)}
          />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end" in={isNavbarOpen}>
            <Nav>
              <Nav.Link as={NavLink} to="/landingPage" className="nav-link-custom" activeClassName="active">{t('Navbar.home')}</Nav.Link>
              <Nav.Link as={NavLink} to="/work" className="nav-link-custom" activeClassName="active">{t('Navbar.work')}</Nav.Link>
              <Nav.Link as={NavLink} to="/about" className="nav-link-custom" activeClassName="active">{t('Navbar.about')}</Nav.Link>
              <Nav.Link as={NavLink} to="/contact" className="nav-link-custom" activeClassName="active">{t('Navbar.contact')}</Nav.Link>
            </Nav>
            <LanguageSwitcher />
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default NavbarMenu;
