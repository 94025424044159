import React, { useState } from 'react';
import './PortfolioPage2024.css';
import {FaReact, FaBootstrap, FaJava, FaLock, FaGlobe, FaDatabase} from 'react-icons/fa';
import { SiSpringboot, SiPostgresql } from 'react-icons/si';
import { Button, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {t} from "i18next";

const PortfolioPage2024 = () => {
    const [openDetails, setOpenDetails] = useState({});
    const { t } = useTranslation();
    const toggleDetails = (section) => {
        setOpenDetails((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <div style={{marginTop: "100px"}}>
            <h1 className="year-text">{t('Work.years.2024')}</h1>
            <GestionnaireStages
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />
            <hr/>
            <GestionnaireTaches
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />
            <hr/>
            <GestionnaireBibliotheque
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />
            <hr/>
            <SiteWebPersonnel
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />


        </div>
    );
};

const GestionnaireStages = ({ toggleDetails, openDetails }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const shortDescription = t('Projects.GestionnaireStages.description').slice(0, 78);
    const fullDescription = t('Projects.GestionnaireStages.description');
    return (
        <div className="row">
            <div className="col-7">
                <img
                    src="/images/porfolio/2024/2024_projetGestionStages.png"
                    alt="projetStage2024"
                    className="portfolio-image p-5"
                />
            </div>

            <div className="col p-5 text-start">
                <h3>
                    <strong>{t('Projects.GestionnaireStages.title')}</strong>
                </h3>
                <a
                    href="https://github.com/Nabil077/glucose"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="github-btn">
                        <i className="fab fa-github"></i>
                    </button>
                </a>
                <br/><br/>
                <p>
                    <strong>{t('Projects.GestionnaireStages.particulariter')}</strong> {t('Projects.GestionnaireStages.particularity')}
                </p>
                <p>
                    <strong>{t('Projects.GestionnaireStages.descriptionTitle')}</strong>{' '}
                    {showFullDescription ? fullDescription : `${shortDescription}...`}{' '}
                    <span
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        className="cursor-pointer"
                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#71E0FF'}}
                    >
                        {showFullDescription
                            ? t('Projects.GestionnaireStages.descriptionOptions.hide')
                            : t('Projects.GestionnaireStages.descriptionOptions.show')}
                    </span>
                </p>
                <br/>

                <h4>Technologies : </h4>
                <ul>
                    <li>
                        <FaReact className="icon"/> React.js
                    </li>
                    <li>
                        <FaBootstrap className="icon"/> Bootstrap
                    </li>
                    <li>
                        <FaJava className="icon"/> Java
                    </li>
                    <li>
                        <SiPostgresql className="icon"/> Postgresql
                    </li>
                    <li>
                        <SiSpringboot className="icon"/> Spring Boot
                    </li>
                    <li>
                        <FaLock className="icon"/> Spring Security
                    </li>
                    <li>
                        <FaGlobe className="icon"/> i18next
                    </li>
                </ul>
                <br/>

                <Button
                    onClick={() => toggleDetails('gestionStages')}
                    aria-controls="details-collapse-gestionStages"
                    aria-expanded={openDetails['gestionStages'] || false}
                    className={`gradient-btn ${openDetails['gestionStages'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['gestionStages'] ? t('Work.details.hide') : t('Work.details.show')}
                </Button>

                <Collapse in={openDetails['gestionStages'] || false}>
                    <div id="details-collapse-gestionStages">
                        <h4>{t('Projects.GestionnaireStages.detail')}</h4>
                        <ul>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.Scrum')}</strong>
                                <ul>
                                    <li>{t('Projects.GestionnaireStages.details.Jira')}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.BackEnd')}</strong>
                            </li>
                            <ul>
                                <li>{t('Projects.GestionnaireStages.details.MVC')}</li>
                            </ul>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.TDD')}</strong>
                            </li>
                            <ul>
                                {t('Projects.GestionnaireStages.details.tools', {returnObjects: true}).map((tool, index) => (
                                    <li key={index}>{tool}</li>
                                ))}
                            </ul>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.security')}</strong>
                            </li>
                            <ul>
                                <li>{t('Projects.GestionnaireStages.details.jwt')}</li>
                                <li>{t('Projects.GestionnaireStages.details.roles')}</li>
                            </ul>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.API')}</strong>
                                <ul>
                                    <li>{t('Projects.GestionnaireStages.details.communicationSecure')}</li>
                                    <li>{t('Projects.GestionnaireStages.details.requestGestion')}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.github')}</strong>
                                <ul>
                                    <li>{t('Projects.GestionnaireStages.details.mainBranch')}</li>
                                    <li>
                                        {t('Projects.GestionnaireStages.details.featureBranches')}.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.i18n')}</strong>
                                <ul>
                                    {t('Projects.GestionnaireStages.details.languages', {returnObjects: true}).map((languages, index) => (
                                        <li key={index}>{languages}</li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <strong>{t('Projects.GestionnaireStages.details.lombok')}</strong>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
}

const GestionnaireTaches = ({toggleDetails, openDetails}) => {
    const {t} = useTranslation();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const shortDescription = t('Projects.GestionnaireTaches.description').slice(0, 191);
    const fullDescription = t('Projects.GestionnaireTaches.description');

    return (
        <div className="row">
            <div className="col-7">
                <img
                    src="/images/porfolio/2024/2024_projetGestionTaches.png"
                    alt="projetTaches2024"
                    className="portfolio-image p-5"
                />
            </div>
            <div className="col p-5 text-start">
                <h3><strong>{t('Projects.GestionnaireTaches.title')}</strong></h3>
                <a
                    href="https://github.com/PDG07/gestionnaireTaches"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="github-btn">
                        <i className="fab fa-github"></i>
                    </button>
                </a>
                <br/><br/>

                <p>
                    <strong>{t('Projects.GestionnaireTaches.descriptionTitle')}</strong>{' '}
                    {showFullDescription ? fullDescription : `${shortDescription}...`}{' '}
                    <span
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        className="cursor-pointer"
                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#71E0FF'}}
                    >
                        {showFullDescription
                            ? t('Projects.GestionnaireTaches.descriptionOptions.hide')
                            : t('Projects.GestionnaireTaches.descriptionOptions.show')}
                    </span>
                </p>
                <br/>

                <h4>{t('Projects.GestionnaireTaches.technologies')} : </h4>
                <ul>
                    <li>
                        <FaReact className="icon"/> React.js
                    </li>
                    <li>
                        <FaBootstrap className="icon"/> Bootstrap
                    </li>
                    <li>
                        <FaJava className="icon"/> Java
                    </li>
                    <li>
                        <FaDatabase className="icon"/> H2 database
                    </li>
                    <li>
                        <SiSpringboot className="icon"/> Spring Boot
                    </li>
                </ul>
                <br/>

                <Button
                    onClick={() => toggleDetails('gestionTaches')}
                    aria-controls="details-collapse-gestionTaches"
                    aria-expanded={openDetails['gestionTaches'] || false}
                    className={`gradient-btn ${openDetails['gestionTaches'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['gestionTaches'] ? t('Work.details.hide') : t('Work.details.show')}
                </Button>

                <Collapse in={openDetails['gestionTaches'] || false}>
                    <div id="details-collapse-gestionTaches">
                        <h4>{t('Projects.GestionnaireTaches.detail')}</h4>
                        <ul>
                            <li>
                                <strong>{t('Projects.GestionnaireTaches.details.BackEnd')} :</strong>
                            </li>
                            <ul>
                                <li>{t('Projects.GestionnaireTaches.details.MVC')}</li>
                            </ul>
                            <li>
                                <strong>{t('Projects.GestionnaireTaches.details.TDD')} :</strong>
                            </li>
                            <ul>
                                <li>{t('Projects.GestionnaireTaches.details.tools.0')}</li>
                                <li>{t('Projects.GestionnaireTaches.details.tools.1')}</li>
                                <li>{t('Projects.GestionnaireTaches.details.tools.2')}</li>
                            </ul>
                            <li>
                                <strong>{t('Projects.GestionnaireTaches.details.github')} :</strong>
                                <ul>
                                    <li>{t('Projects.GestionnaireTaches.details.mainBranch')}</li>
                                    <li>{t('Projects.GestionnaireTaches.details.featureBranches')}</li>
                                </ul>
                            </li>
                            <li>
                                <strong>{t('Projects.GestionnaireTaches.details.lombok')}</strong>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

const SiteWebPersonnel = ({toggleDetails, openDetails}) => {
    const {t} = useTranslation();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const shortDescription = t('Projects.SiteWebPersonnel.description').slice(0, 151);
    const fullDescription = t('Projects.SiteWebPersonnel.description');

    return (
        <div className="row">
            <div className="col-7">
                <img
                    src="/images/porfolio/2024/2024_projetSiteWebPersonnel.png"
                    alt="projetStage2024"
                    className="portfolio-image p-5"
                />
            </div>

            <div className="col p-5 text-start">
            <h3>
                    <strong>{t('Projects.SiteWebPersonnel.title')}</strong>
                </h3>
                <br/>

                <p>
                    <strong>{t('Projects.SiteWebPersonnel.descriptionTitle')}</strong>{' '}
                    {showFullDescription ? fullDescription : `${shortDescription}...`}{' '}
                    <span
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        className="cursor-pointer"
                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#71E0FF'}}
                    >
                        {showFullDescription
                            ? t('Projects.SiteWebPersonnel.descriptionOptions.hide')
                            : t('Projects.SiteWebPersonnel.descriptionOptions.show')}
                    </span>
                </p><br/>

                <h4>{t('Projects.SiteWebPersonnel.technologies')}</h4>
                <ul>
                    <li>
                        <FaReact className="icon"/> React.js
                    </li>
                    <li>
                        <FaBootstrap className="icon"/> Bootstrap
                    </li>
                    <li>
                        <FaGlobe className="icon"/> i18next
                    </li>
                    <li>
                        <i className="fas fa-envelope"></i> FormSpree
                    </li>
                </ul>
                <br/>

                <Button
                    onClick={() => toggleDetails('siteWebPersonnel')}
                    aria-controls="details-collapse-siteWebPersonnel"
                    aria-expanded={openDetails['siteWebPersonnel'] || false}
                    className={`gradient-btn ${openDetails['siteWebPersonnel'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['siteWebPersonnel'] ? t('Work.details.hide') : t('Work.details.show')}
                </Button>

                <Collapse in={openDetails['siteWebPersonnel'] || false}>
                    <div id="details-collapse-siteWebPersonnel">
                        <h4>{t('Projects.SiteWebPersonnel.detail')}</h4>
                        <ul>
                            <li>
                                <strong>{t('Projects.SiteWebPersonnel.details.i18n')} :</strong>
                                <ul>
                                    <li>{t('Projects.SiteWebPersonnel.details.languages.0')}</li>
                                    <li>{t('Projects.SiteWebPersonnel.details.languages.1')}</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
}

const GestionnaireBibliotheque = ({toggleDetails, openDetails}) => {
    const {t} = useTranslation();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const shortDescription = t('Projects.GestionnaireBibliotheque.description').slice(0, 150);
    const fullDescription = t('Projects.GestionnaireBibliotheque.description');

    return (
        <div className="row">
            <div className="col-7">
                <img
                    src="/images/porfolio/2024/2024_projetGestionnaireBibliotheque.png"
                    alt="projetGestionnaireBibliotheque2024"
                    className="portfolio-image p-5"
                />
            </div>

            <div className="col p-5 text-start">
                <h3>
                    <strong>{t('Projects.GestionnaireBibliotheque.title')}</strong>
                </h3>
                <a
                    href="https://github.com/Nabil077/tp4-420-445-h24-Nabil077"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="github-btn">
                        <i className="fab fa-github"></i>
                    </button>
                </a>
                <br/><br/>

                <p>
                    <strong>{t('Projects.GestionnaireBibliotheque.descriptionTitle')}</strong>{' '}
                    {showFullDescription ? fullDescription : `${shortDescription}...`}{' '}
                    <span
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        className="cursor-pointer"
                        style={{cursor: 'pointer', textDecoration: 'underline', color: '#71E0FF'}}
                    >
                        {showFullDescription
                            ? t('Projects.GestionnaireBibliotheque.descriptionOptions.hide')
                            : t('Projects.GestionnaireBibliotheque.descriptionOptions.show')}
                    </span>
                </p><br/>

                <h4>{t('Projects.GestionnaireBibliotheque.technologies')}</h4>
                <ul>
                    <li>
                        <FaReact className="icon"/> React.js
                    </li>
                    <li>
                        <FaJava className="icon"/> Java
                    </li>
                    <li>
                        <FaDatabase className="icon"/> H2 database
                    </li>
                    <li>
                        <SiSpringboot className="icon"/> Spring Boot
                    </li>
                </ul>
                <br/>

                <Button
                    onClick={() => toggleDetails('GestionnaireBibliotheque')}
                    aria-controls="details-collapse-GestionnaireBibliotheque"
                    aria-expanded={openDetails['GestionnaireBibliotheque'] || false}
                    className={`gradient-btn ${openDetails['GestionnaireBibliotheque'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['siteWebPersonnel'] ? t('Work.details.hide') : t('Work.details.show')}
                </Button>

                <Collapse in={openDetails['GestionnaireBibliotheque'] || false}>
                    <div id="details-collapse-GestionnaireBibliotheque">
                        <h4>{t('Projects.GestionnaireBibliotheque.detail')}</h4>
                        <ul>
                            <li>
                                <li>
                                    <strong>{t('Projects.GestionnaireBibliotheque.details.BackEnd')} :</strong>
                                </li>
                                <ul>
                                    <li>{t('Projects.GestionnaireBibliotheque.details.MVC')}</li>
                                </ul>
                                <li>
                                    <strong>{t('Projects.GestionnaireBibliotheque.details.TDD')} :</strong>
                                </li>
                                <ul>
                                    <li>{t('Projects.GestionnaireBibliotheque.details.tools.0')}</li>
                                    <li>{t('Projects.GestionnaireBibliotheque.details.tools.1')}</li>
                                    <li>{t('Projects.GestionnaireBibliotheque.details.tools.2')}</li>
                                </ul>
                                <li>
                                    <strong>{t('Projects.GestionnaireBibliotheque.details.github')} :</strong>
                                    <ul>
                                        <li>{t('Projects.GestionnaireBibliotheque.details.mainBranch')}</li>
                                        <li>{t('Projects.GestionnaireBibliotheque.details.featureBranches')}</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>{t('Projects.GestionnaireBibliotheque.details.lombok')}</strong>
                                </li>
                            </li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
}


export default PortfolioPage2024;

