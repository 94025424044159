import React from 'react';
import { useParams } from 'react-router-dom';
import PortfolioPage2024 from "./years/2024/PortfolioPage2024";
import PortfolioPage2023 from "./years/2023/PortfolioPage2023";
import {useTranslation} from "react-i18next";

const PortfolioPage = () => {
    const { year } = useParams();
    const { t } = useTranslation();

    const renderPortfolio = (year) => {
        switch (year) {
            case '2024':
                return <PortfolioPage2024 />;
            case '2023':
                return <PortfolioPage2023 />;
            default:
                return <div>{t('errors.porfolioNotAvailable')}</div>;
        }
    };

    return (
        <div>
            {renderPortfolio(year)}
        </div>
    );
};

export default PortfolioPage;
