import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './WelcomePage.css';
import { useTranslation } from 'react-i18next';

function WelcomePage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate("/landingPage");
        }, 4500);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div className="welcome-page">
            <div className="welcome-container">
                <h1 className="welcome-message">{t('WelcomePage.welcome')}</h1>
                <h2 className="guest-message">{t('WelcomePage.guest')}</h2>
            </div>
        </div>
    );
}

export default WelcomePage;
