import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            errors : {
                porfolioNotAvailable : "Portfolio not available for this year."
            },
            Navbar: {
                home: "Home",
                work: "Projects",
                about: "About",
                contact: "Contact",
            },
            EndLandingPage: {
                workTogheter : "Let's work together",
                contact: "Contact me",
                emailCopiedSuccesfuly: "Email copied to clipboard !",
            },
            LandingPage: {
                intro : "Hi, I'm Nabil",
                imA : "I'm a",
                carriere: "Developer/Student",
                objectif: "Currently looking for new experiences, internships and jobs.",
                talk: "Let's talk",
            },
            Contact : {
                sweetAlert:{
                    title : "Thank you !",
                    message : "Your message has been sent successfully.",
                    confirmButtonText : "OK",
                },
                workTogheter : "Let's work together",
                nom : "Name",
                prenom : "First name",
                email : "E-mail address",
                phone : "Phone number",
                message : "Type your message here",
                sendMessage : "Send message",
                sendingMessage : "Sending message...",
            },
            Work: {
                years: {
                    2024: "2024",
                    2023: "2023",
                },
                details: {
                    show: "Show details",
                    hide: "Hide details",
                },
            },
            About: {
                passionate : "Passionate about IT, I specialized in this field to combine my technical",
                curiosity : "curiosity with my personal interests.",
                experienceProfessionelle: "Professional Experiences",
                studentWork: "Student Work",
                intro: "Hi, I’m",
                studentWorkDetails: {
                    locatif : "Rental management assistant",
                    antoine : "Antoine.ca - Help with coolers",
                },
                languagesProgramming: "Programming languages : ",
                frameworksBibliotheques: "Frameworks and libraries : ",
                outilsPlateformes: "Tools and platforms : ",
                conceptionArchitecture : "Design and architecture : ",
                gestionProjet: "Methodologies and project management :",
                testDev: "Tests and development :",
                competences : "Skills",
            },
            WelcomePage:{
                welcome: "Welcome",
                guest: "Dear guest",
            },
            Projects: {
                WebApplicationWithAPI: {
                    title: 'Interactive Web Application with API and Puzzle',
                    description: 'Objective: Develop a web application for form validation, puzzle management, and interaction with a secure API.',
                    technologies: 'Technologies:',
                    detailsButton: {
                        show: 'Show Details',
                        hide: 'Hide Details'
                    },
                    detail : "Details :",
                    details: {
                        development: 'Front/Back-End integrated development',
                        multithreading: 'Multithreading and DOM management',
                        errorHandling: 'Advanced error handling'
                    }
                },
                MemoryGame: {
                    title: 'Development of an Enhanced Memory Game',
                    description: 'Objective: Create a memory game where users need to match sets of cards.',
                    technologies: 'Technologies :',
                    UI: "UI/UX and event management",
                    angular: "Angular",
                    detailsButton: {
                        show: 'Show Details',
                        hide: 'Hide Details'
                    },
                    detail : "Details :",
                    details: {
                        domManipulation: 'DOM manipulation with TypeScript',
                        dynamicCards: 'Dynamic card creation',
                        improvedLogic: 'Improved algorithmic logic'
                    }
                },
                MeetingPlanner: {
                    title: 'Meeting Planner Application',
                    description: 'Objective: Develop a web application to schedule meetings between multiple participants.',
                    technologies: 'Technologies :',
                    angular: "Angular",
                    firebaseFunctions: "Firebase Functions",
                    imagePlaceholder: "Image not available",
                    detailsButton: {
                        show: 'Show Details',
                        hide: 'Hide Details'
                    },
                    detail : "Details :",
                    details: {
                        authAndSessions: 'Authentication and user session management',
                        dataAnalysis: 'Data analysis with BigQuery',
                        secureScheduling: 'Secure scheduling via Firebase'
                    }
                },
                GestionnaireStages: {
                    title: "Develop a secure transactional web application for internship management.",
                    particulariter: "Particularity :",
                    descriptionTitle : "Description :",
                    description : "This project aims to design and develop a secure transactional web application for the centralized management of internships. By leveraging modern technologies such as RESTful APIs and a multi-tier architecture, the platform will automate and optimize administrative processes, while ensuring compliance with best practices in application security. Accessible via a browser, it will offer a smooth user experience and integrate advanced features for role management, transaction traceability and collaboration between students, tutors and companies.",
                    descriptionOptions : {
                        hide: "Hide description",
                        show: "Show description",
                    },
                    particularity: "In a team of 6 SCRUM developers",
                    technologies: "Technologies",
                    detail : "Details :",
                    details: {
                        Scrum: "Scrum",
                        Jira: "Jira",
                        BackEnd: "Back-end architecture",
                        MVC: "MVC (Model–view–controller)",
                        TDD: "Test Driven Development",
                        tools: ["JUnit", "Mockito", "MockMvc"],
                        security: "Spring Boot Security",
                        jwt: "JSON Web Tokens (JWT)",
                        roles: "Role-based authorization",
                        API: "API",
                        communicationSecure: "Secure communication via tokens",
                        requestGestion : "Request management with React and dynamic components",
                        github: "GitHub",
                        mainBranch: "Main branch for stable releases.",
                        featureBranches: "Feature-specific branches for development.",
                        i18n: "Internationalization",
                        languages: ["French", "English"],
                        lombok: "Lombok",
                    },
                },
                GestionnaireTaches: {
                    title: "Develop a task management application.",
                    technologies: "Technologies",
                    descriptionTitle : "Description : ",
                    description : "This project involves developing a task management application using React.js, Bootstrap, Java, H2 database, and Spring Boot. The backend follows the MVC architecture for maintainable code and employs Test-Driven Development (TDD) with JUnit, Mockito, and MockMvc to ensure reliability. Version control is managed on GitHub with stable main branches and feature-specific branches. Lombok is used to simplify code by reducing boilerplate. The application offers an intuitive and seamless user interface for efficient task management.",
                    descriptionOptions : {
                        hide: "Hide description",
                        show: "Show description",
                    },
                    detail : "Details :",
                    details: {
                        MVC : "MVC (Model–view–controller)",
                        BackEnd: "Back-end architecture",
                        TDD: "Test Driven Development",
                        tools: ["JUnit", "Mockito", "MockMvc"],
                        github: "GitHub",
                        lombok: "Lombok",
                        mainBranch: "Branche principale pour les versions stables.",
                        featureBranches: "Branches spécifiques aux nouvelles fonctionnalités en développement.",
                    },
                },
                SiteWebPersonnel: {
                    title: "Develop a portfolio website.",
                    technologies: "Technologies",
                    descriptionTitle : "Description : ",
                    description : "This project aims to design and develop a modern and interactive portfolio website to showcase professional and academic achievements. Leveraging modern technologies such as React.js, Bootstrap, and a modular architecture, the site will present projects in a visually appealing and organized manner. Accessible via a web browser, it will provide smooth and intuitive navigation, with customizable sections for skills, experiences, projects, and testimonials.",
                    descriptionOptions : {
                        hide: "Hide description",
                        show: "Show description",
                    },
                    detail : "Details :",
                    details: {
                        i18n: "Internationalization",
                        languages: ["French", "English"],
                    },
                },
                GestionnaireBibliotheque: {
                    title: "Develop a library management application.",
                    technologies: "Technologies :",
                    descriptionTitle : "Description : ",
                    description : "\n" +
                        "This project involves developing a web application for library management using React.js for a dynamic and smooth interface. The back-end will be built with Spring Boot and Java, applying the MVC architecture for clear separation of concerns. The application will follow the Test Driven Development (TDD) methodology, using JUnit, Mockito, and MockMvc to ensure code reliability. Data will be stored in an H2 database. It will allow users to manage books, loans, and users, while providing a simple and intuitive interface for searching and managing resources.",
                    descriptionOptions : {
                        hide: "Hide description",
                        show: "Show description",
                    },
                    detail : "Détails :",
                    details: {
                        MVC : "MVC (Model-View-Controller)",
                        BackEnd: "Architecture Back-end",
                        TDD: "Test Driven Development",
                        tools: ["JUnit", "Mockito", "MockMvc"],
                        github: "GitHub",
                        lombok: "Lombok",
                        mainBranch: "Main branch for stable releases.",
                        featureBranches: "Feature-specific branches for development.",
                    },
                },
            },
        },
    },
    fr: {
        translation: {
            errors : {
                porfolioNotAvailable : "Portfolio non disponible pour cette année."
            },
            Navbar: {
                home: "Accueil",
                work: "Projets",
                about: "À propos",
                contact: "Contact",
            },
            EndLandingPage: {
                workTogheter : "Travaillons ensemble",
                contact: "Contactez-moi",
                emailCopiedSuccesfuly: "Email copié dans le presse-papiers !",
            },
            LandingPage: {
                intro : "Salut, c'est Nabil",
                imA : "",
                carriere: "Développeur/Étudiant",
                objectif: "Actuellement à la recherche de nouvelles expériences, de stages et d'emplois.",
                talk: "Parlons-en",
            },
            Contact : {
                sweetAlert:{
                    title : "Merci !",
                    message : "Votre message a été envoyé avec succès.",
                    confirmButtonText : "OK",
                },
                workTogheter : "Travaillons ensemble",
                nom : "Nom",
                prenom : "Prénom",
                email : "Adresse e-mail",
                phone : "Numéro de téléphone",
                message : "Tapez votre message ici",
                sendMessage : "Envoyer le message",
                sendingMessage : "Envoi du message...",
            },
            Work: {
                years: {
                    2024: "2024",
                    2023: "2023",
                },
                details: {
                    show: "Afficher les détails",
                    hide: "Cacher les détails",
                },
            },
            About: {
                passionate: "Passionné par l'informatique, je me suis spécialisé dans ce domaine pour allier ma",
                curiosity: "curiosité technique à mes intérêts personnels.",
                experienceProfessionelle: "Expériences professionnelles",
                competences : "Compétences",
                studentWork: "Travail étudiant",
                intro: "Salut, je suis",
                studentWorkDetails: {
                    locatif : "Assistant en gestion locative",
                    antoine : "Antoine.ca - Aide aux glacières",
                },
                languagesProgramming: "Langages de programmation : ",
                frameworksBibliotheques: "Frameworks et bibliothèques : ",
                outilsPlateformes: "Outils et plateformes : ",
                conceptionArchitecture : "Conception et architecture : ",
                gestionProjet: "Méthodologies et gestion de projet :",
                testDev: "Tests et développement :",
            },
            WelcomePage:{
                welcome: "Bienvenue",
                guest: "Cher visiteur",
            },
            Projects: {
                WebApplicationWithAPI: {
                    title: 'Application Web Interactif avec API et Puzzle',
                    description: 'Objectif : Développer une application web permettant la validation d\'un formulaire, la gestion d\'un puzzle et l\'interaction avec une API sécurisée.',
                    technologies: 'Technologies :',
                    detailsButton: {
                        show: 'Afficher les détails',
                        hide: 'Cacher les détails'
                    },
                    detail : "Détails :",
                    details: {
                        development: 'Développement Front/Back-End intégré',
                        multithreading: 'Multithreading et gestion du DOM',
                        errorHandling: 'Gestion avancée des erreurs'
                    }
                },
                MemoryGame: {
                    title: 'Développement d\'un Jeu de Mémoire Amélioré',
                    description: 'Objectif : Créer un jeu de mémoire où les utilisateurs doivent trouver des ensembles de cartes.',
                    technologies: 'Technologies :',
                    UI: "UI/UX et gestion des événements",
                    angular: "Angular",
                    detailsButton: {
                        show: 'Afficher les détails',
                        hide: 'Cacher les détails'
                    },
                    detail : "Détails :",
                    details: {
                        domManipulation: 'Manipulation du DOM avec Typescript',
                        dynamicCards: 'Création dynamique des cartes',
                        improvedLogic: 'Amélioration de la logique algorithmique'
                    }
                },
                MeetingPlanner: {
                    title: 'Application de Planification de Rencontres',
                    description: 'Objectif : Développer une application web permettant de planifier des rencontres entre plusieurs participants.',
                    technologies: 'Technologies :',
                    angular: "Angular",
                    firebaseFunctions: "Fonctions Firebase",
                    imagePlaceholder: "Image non disponible",
                    detailsButton: {
                        show: 'Afficher les détails',
                        hide: 'Cacher les détails'
                    },
                    detail : "Détails :",
                    details: {
                        authAndSessions: 'Authentification et gestion des sessions utilisateurs',
                        dataAnalysis: 'Analyse des données avec BigQuery',
                        secureScheduling: 'Planification sécurisée via Firebase'
                    }
                },
                GestionnaireStages: {
                    title: "Développer une application web transactionnelle sécurisée de gestion de stages.",
                    particulariter: "Particularité :",
                    particularity: "En équipe de 6 développeurs SCRUM",
                    descriptionTitle : "Description : ",
                    description : "Ce projet vise à concevoir et développer une application web transactionnelle sécurisée pour la gestion centralisée des stages. En exploitant des technologies modernes comme les APIs RESTful et une architecture multi-niveaux, la plateforme permettra d’automatiser et d’optimiser les processus administratifs, tout en garantissant la conformité aux meilleures pratiques en matière de sécurité applicative. Accessible via un navigateur, elle offrira une expérience utilisateur fluide et intégrera des fonctionnalités avancées de gestion des rôles, de traçabilité des transactions et de collaboration entre étudiants, tuteurs et entreprises.",
                    descriptionOptions : {
                        hide: "Cacher la description",
                        show: "Afficher la description",
                    },
                    technologies: "Technologies",
                    detail : "Détails :",
                    details: {
                        Scrum: "Scrum",
                        Jira: "Jira",
                        BackEnd: "Architecture Back-end",
                        MVC: "MVC (Model–view–controller)",
                        TDD: "Test Driven Development",
                        tools: ["JUnit", "Mockito", "MockMvc"],
                        security: "Spring Boot Security",
                        jwt: "JSON Web Tokens (JWT)",
                        roles: "Autorisation par rôles",
                        API: "API",
                        communicationSecure: "Communication sécurisée via tokens",
                        requestGestion : "Gestion des requêtes avec React et des composants dynamiques",
                        github: "GitHub",
                        mainBranch: "Branche principale pour les versions stables.",
                        featureBranches: "Branches spécifiques aux nouvelles fonctionnalités en développement.",
                        i18n: "Internationalisation",
                        languages: ["Français", "Anglais"],
                        lombok: "Lombok",
                    },
                },
                GestionnaireTaches: {
                    title: "Développer une application de gestion de tâches.",
                    technologies: "Technologies",
                    descriptionTitle : "Description : ",
                    description : "Ce projet consiste à développer une application de gestion de tâches utilisant React.js, Bootstrap, Java, la base de données H2 et Spring Boot. Le backend suit l'architecture MVC pour un code maintenable et utilise le développement piloté par les tests (TDD) avec JUnit, Mockito et MockMvc pour garantir la fiabilité. Le contrôle des versions est géré sur GitHub avec des branches principales pour les versions stables et des branches spécifiques aux nouvelles fonctionnalités. Lombok est utilisé pour simplifier le code en réduisant le code répétitif. L'application offre une interface utilisateur intuitive et fluide pour une gestion des tâches efficace.",
                    descriptionOptions : {
                        hide: "Cacher la description",
                        show: "Afficher la description",
                    },
                    detail : "Détails :",
                    details: {
                        MVC : "MVC (Model-Vue-Controller)",
                        BackEnd: "Architecture Back-end",
                        TDD: "Test Driven Development",
                        tools: ["JUnit", "Mockito", "MockMvc"],
                        github: "GitHub",
                        lombok: "Lombok",
                        mainBranch: "Branche principale pour les versions stables.",
                        featureBranches: "Branches spécifiques aux nouvelles fonctionnalités en développement.",

                    },
                },
                SiteWebPersonnel: {
                    title: "Développer un site portfolio.",
                    technologies: "Technologies :",
                    descriptionTitle : "Description : ",
                    description : "Ce projet vise à concevoir et développer un site portfolio moderne et interactif pour mettre en valeur des réalisations professionnelles et académiques. En exploitant des technologies modernes telles que React.js, Bootstrap, et une architecture modulaire, le site permettra de présenter des projets de manière visuellement attrayante et organisée. Accessible via un navigateur, il offrira une navigation fluide et intuitive, avec des sections personnalisables pour les compétences, les expériences, les projets et les témoignages.",
                    descriptionOptions : {
                        hide: "Cacher la description",
                        show: "Afficher la description",
                    },
                    detail : "Détails :",
                    details: {
                        i18n: "Internationalisation",
                        languages: ["Français", "Anglais"],
                    },
                },
                GestionnaireBibliotheque: {
                    title: "Développer une application de gestion de bibliothèque.",
                    technologies: "Technologies :",
                    descriptionTitle : "Description : ",
                    description : "\n" +
                        "Ce projet consiste à développer une application web de gestion de bibliothèque utilisant React.js pour une interface dynamique et fluide. Le back-end sera construit avec Spring Boot et Java, en appliquant l'architecture MVC pour une séparation claire des préoccupations. L'application suivra la méthodologie Test Driven Development (TDD), en utilisant JUnit, Mockito et MockMvc pour garantir la fiabilité du code. Les données seront stockées dans une base de données H2. Elle permettra aux utilisateurs de gérer les livres, les emprunts et les utilisateurs, tout en offrant une interface simple et intuitive pour la recherche et la gestion des ressources.",
                    descriptionOptions : {
                        hide: "Cacher la description",
                        show: "Afficher la description",
                    },
                    detail : "Détails :",
                    details: {
                        MVC : "MVC (Model-Vue-Controller)",
                        BackEnd: "Architecture Back-end",
                        TDD: "Test Driven Development",
                        tools: ["JUnit", "Mockito", "MockMvc"],
                        github: "GitHub",
                        lombok: "Lombok",
                        mainBranch: "Branche principale pour les versions stables.",
                        featureBranches: "Branches spécifiques aux nouvelles fonctionnalités en développement.",
                    },
                },
            },
        },
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fr",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
