import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Work.css';

const years = [2024, 2023];

const Work = () => {
    const navigate = useNavigate();

    const handleClick = (year) => {
        navigate(`/portfolio/${year}`);
    };

    return (
        <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
            <h2 className="portfolio-title text-center mb-5">Portfolio</h2>
            <div className="col-11">
                {years.map((year, index) => (
                    <div
                        key={index}
                        className="col-12 mt-4 clickable-container"
                        style={{
                            height: '200px',
                            background: '#28272E',
                            marginBottom: '30px',
                        }}
                        onClick={() => handleClick(year)}
                    >
                        <div className="year-container">
                            <span className="year-text">{year}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Work;
