import React, { useState } from 'react';
import './PortfolioPage2023.css';
import { FaAngular, FaPuzzlePiece, FaGamepad, FaCalendarAlt } from 'react-icons/fa';
import { Button, Collapse } from 'react-bootstrap';
import {t} from "i18next";
import {useTranslation} from "react-i18next";

const PortfolioPage2023 = () => {
    const [openDetails, setOpenDetails] = useState({});
    const { t } = useTranslation();

    const toggleDetails = (section) => {
        setOpenDetails((prevState) => ({
            ...prevState,
            [section]: !prevState[section],
        }));
    };

    return (
        <div style={{ marginTop: "100px" }}>
            <h1 className="year-text">{t('Work.years.2023')}</h1>
            <WebApplicationWithAPI
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />
            <hr />
            <MemoryGame
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />
            <hr />
            <MeetingPlanner
                toggleDetails={toggleDetails}
                openDetails={openDetails}
            />
        </div>
    );
};

const WebApplicationWithAPI = ({ toggleDetails, openDetails }) => {
    return (
        <div className="row">
            <div className="col-7">
                <img
                    src="/images/porfolio/2023/2023_Puzzle.png"
                    alt={t('Projects.WebApplicationWithAPI.title')}
                    className="portfolio-image p-5"
                />
            </div>
            <div className="col p-5 text-start">
                <h3>
                    <strong>{t('Projects.WebApplicationWithAPI.title')}</strong>
                </h3>
                <p>{t('Projects.WebApplicationWithAPI.description')}</p>
                <h4>{t('Projects.WebApplicationWithAPI.technologies')}</h4>
                <ul>
                    <li>
                        <FaAngular className="icon" /> Angular
                    </li>
                    <li>
                        <FaPuzzlePiece className="icon" /> Gestion de puzzles
                    </li>
                    <li>
                        <i className="fas fa-lock"></i> Sécurité (Token)
                    </li>
                </ul>

                <Button
                    onClick={() => toggleDetails('webApplicationWithAPI')}
                    aria-controls="details-collapse-webApplicationWithAPI"
                    aria-expanded={openDetails['webApplicationWithAPI'] || false}
                    className={`gradient-btn ${openDetails['webApplicationWithAPI'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['webApplicationWithAPI']
                        ? t('Projects.WebApplicationWithAPI.detailsButton.hide')
                        : t('Projects.WebApplicationWithAPI.detailsButton.show')}
                </Button>

                <Collapse in={openDetails['webApplicationWithAPI'] || false}>
                    <div id="details-collapse-webApplicationWithAPI">
                        <h4>{t('Projects.WebApplicationWithAPI.detail')}</h4>
                        <ul>
                            <li>{t('Projects.WebApplicationWithAPI.details.development')}</li>
                            <li>{t('Projects.WebApplicationWithAPI.details.multithreading')}</li>
                            <li>{t('Projects.WebApplicationWithAPI.details.errorHandling')}</li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

const MemoryGame = ({ toggleDetails, openDetails }) => {
    return (
        <div className="row">
            <div className="col-7">
                <img
                    src="/images/porfolio/2023/2023_JeuMemoire.png"
                    alt={t('Projects.MemoryGame.title')}
                    className="portfolio-image p-5 img-fluid"
                />
            </div>
            <div className="col p-5 text-start">
                <h3>
                    <strong>{t('Projects.MemoryGame.title')}</strong>
                </h3>
                <p>{t('Projects.MemoryGame.description')}</p>
                <h4>{t('Projects.MemoryGame.technologies')}</h4>
                <ul>
                    <li>
                        <FaAngular className="icon" /> {t('Projects.MemoryGame.angular')}
                    </li>
                    <li>
                        <FaGamepad className="icon" /> {t('Projects.MemoryGame.UI')}
                    </li>
                </ul>

                <Button
                    onClick={() => toggleDetails('memoryGame')}
                    aria-controls="details-collapse-memoryGame"
                    aria-expanded={openDetails['memoryGame'] || false}
                    className={`gradient-btn ${openDetails['memoryGame'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['memoryGame']
                        ? t('Projects.MemoryGame.detailsButton.hide')
                        : t('Projects.MemoryGame.detailsButton.show')}
                </Button>

                <Collapse in={openDetails['memoryGame'] || false}>
                    <div id="details-collapse-memoryGame">
                        <h4>{t('Projects.MemoryGame.detail')}</h4>
                        <ul>
                            <li>{t('Projects.MemoryGame.details.domManipulation')}</li>
                            <li>{t('Projects.MemoryGame.details.dynamicCards')}</li>
                            <li>{t('Projects.MemoryGame.details.improvedLogic')}</li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

const MeetingPlanner = ({ toggleDetails, openDetails }) => {
    return (
        <div className="row">
            <div className="col-7">
                <div className="image-placeholder p-5">
                    <span className="placeholder-text">{t('Projects.MeetingPlanner.imagePlaceholder')}</span>
                </div>
            </div>

            <div className="col p-5 text-start">
                <h3>
                    <strong>{t('Projects.MeetingPlanner.title')}</strong>
                </h3>
                <p>{t('Projects.MeetingPlanner.description')}</p>
                <h4>{t('Projects.MeetingPlanner.technologies')}</h4>
                <ul>
                    <li>
                        <FaAngular className="icon" /> {t('Projects.MeetingPlanner.angular')}
                    </li>
                    <li>
                        <FaCalendarAlt className="icon" /> {t('Projects.MeetingPlanner.firebaseFunctions')}
                    </li>
                </ul>

                <Button
                    onClick={() => toggleDetails('meetingPlanner')}
                    aria-controls="details-collapse-meetingPlanner"
                    aria-expanded={openDetails['meetingPlanner'] || false}
                    className={`gradient-btn ${openDetails['meetingPlanner'] ? 'open' : 'closed'} mb-4`}
                >
                    {openDetails['meetingPlanner']
                        ? t('Projects.MeetingPlanner.detailsButton.hide')
                        : t('Projects.MeetingPlanner.detailsButton.show')}
                </Button>

                <Collapse in={openDetails['meetingPlanner'] || false}>
                    <div id="details-collapse-meetingPlanner">
                        <h4>{t('Projects.MeetingPlanner.detail')}</h4>
                        <ul>
                            <li>{t('Projects.MeetingPlanner.details.authAndSessions')}</li>
                            <li>{t('Projects.MeetingPlanner.details.dataAnalysis')}</li>
                            <li>{t('Projects.MeetingPlanner.details.secureScheduling')}</li>
                        </ul>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default PortfolioPage2023;
