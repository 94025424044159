import React, {useEffect, useState} from 'react';
import './Contact.css';
import { useForm, ValidationError } from '@formspree/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from 'react-i18next';

const MySwal = withReactContent(Swal);

function Contact() {
    const [phone, setPhone] = useState('');
    const [state, handleSubmit] = useForm("movqnnol");
    const { t } = useTranslation();

    const handlePhoneChange = (e) => {
        let value = e.target.value;

        value = value.replace(/\D/g, '');

        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        if (value.length <= 3) {
            value = `(${value}`;
        } else if (value.length <= 6) {
            value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
        } else {
            value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }

        setPhone(value);
    };

    const handlePhoneBlur = () => {
        if (phone.length === 1) {
            setPhone('');
        }
    };

    useEffect(() => {
        if (state.succeeded) {
            MySwal.fire({
                title: t('Contact.sweetAlert.title'),
                text: t('Contact.sweetAlert.message'),
                icon: 'success',
                confirmButtonText: t('Contact.sweetAlert.confirmButtonText'),
            }).then(() => {
                setPhone('');
            });
        }
    }, [state.succeeded, t]);

    return (
        <div className="min-vh-100 d-flex flex-column pt-2" style={{marginTop: "50px"}}>
            <h1 className="m-5 text-start animate-text">Nabil <span style={{ color: "#2E67F8" }}>.</span></h1>

            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="form-container">
                        <form className="form" onSubmit={handleSubmit}>
                            <h2 className="letter-spacing maven-font text-start" style={{ color: "#71E0FF" }}>{t('Contact.workTogheter')}</h2>

                            {/* Première ligne : Nom et Prénom */}
                            <div className="form-row mb-4 mt-3">
                                <input
                                    type="text"
                                    className="form-input"
                                    placeholder={t("Contact.nom")}
                                    required
                                    name="nom"
                                />
                                <input
                                    type="text"
                                    className="form-input"
                                    placeholder={t("Contact.prenom")}
                                    required
                                    name="prenom"
                                />
                            </div>

                            {/* Deuxième ligne : Adresse email */}
                            <div className="form-row mb-4">
                                <input
                                    type="email"
                                    className="form-input"
                                    placeholder={t("Contact.email")}
                                    required
                                    name="email"
                                />
                                <input
                                    type="text"
                                    className="form-input"
                                    placeholder={t("Contact.phone")}
                                    value={phone}
                                    onBlur={handlePhoneBlur}
                                    onChange={handlePhoneChange}
                                    maxLength="14"
                                    name="phone"
                                />
                            </div>

                            {/* Troisième ligne : Zone de texte */}
                            <div className="form-row">
                                <textarea
                                    className="form-input message"
                                    placeholder={t("Contact.message")}
                                    required
                                    name="message"
                                />
                            </div>

                            {/* Quatrième ligne : Bouton send message */}
                            <div className="form-row justify-content-center mt-3">
                                <button type="submit" className="send-button" disabled={state.submitting}>
                                    {state.submitting ? t('Contact.sendingMessage') : t('Contact.sendMessage')}
                                </button>
                            </div>
                        </form>

                        {/* Affichage des erreurs de validation */}
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                        <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="col-lg-5 d-flex justify-content-center align-items-center order-2 order-lg-1 animate-img">
                        <img src="/images/imageWorker.png" alt="KnowledgeRatioByHeart" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
