import React, {useState} from "react";
import "./EndLandingPage.css";
import {WiSunrise} from "react-icons/wi";
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';

function EndLandingPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleNavigation = () => {
        navigate("/contact");
    };

    const [copied, setCopied] = useState(false);

    const handleCopyEmail = () => {
        navigator.clipboard.writeText("nabil.hasnaoui.it@gmail.com")
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 3000);
            })
            .catch((err) => console.error("Failed to copy email: ", err));
    };

    return (
        <div className="" style={{minHeight: "100vh"}}>
            <div className="row end-landing-page">
                <div className="col">
                    <h1><WiSunrise className="sunrise-icon" /> {t('EndLandingPage.workTogheter')}</h1>
                </div>
            </div>
            <hr/>
            <div className="row">
                <div className="col-2"></div>
                <div className="col-lg-4 text-center mb-3">
                    <button className="contact-btn" onClick={handleCopyEmail}>
                        nabil.hasnaoui.it@gmail.com
                    </button>
                    {copied && <p className="copy-feedback">{t('EndLandingPage.emailCopiedSuccesfuly')}</p>}
                </div>
                <div className="col-lg-4 text-center mb-5">
                    <button className="contact-btn" onClick={handleNavigation}>{t('EndLandingPage.contact')}</button>
                </div>
            </div>
        </div>
    );
}

export default EndLandingPage;
